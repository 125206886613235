export const images = {
    APPSTORE: require('../assets/images/Appstore1.jpg'),
    GOOGLE: require('../assets/images/Google1.jpg'),
    LOGO: require('../assets/images/VisuEats.png'),
    HOME_LOGO: require('../assets/images/Vector.png'),
    NO_FOUND: require('../assets/images/noFound.png'),
    IMG_SPICE: require('../assets/images/spice-logo.png'),
    IMG_SPICE_COLOR: require('../assets/images/spice-color-logo.png'),
    IMG_SEARCH: require('../assets/images/searchbar.png'),
    IMG_BACKARROW: require('../assets/images/back-arrow.png'),
    IMG_CROSS: require('../assets/images/cross-icon.png'),
    IMG_VISUEATS_COLOR: require('../assets/images/visueatsColor.png'),
    IMG_RESTAURANT: require('../assets/images/Rectangle.png'),
    IMG_SPONSOR: require('../assets/images/sponsor.png'),
    IMG_ERROR: require('../assets/images/error-image.png'),
    IMG_DEFAULT_IMAGE: require('../assets/images/default-cover.png'),
    IMG_DEFAULT_LOGO: require('../assets/images/default-logo.png'),
    IMG_RESERVE: require('../assets/images/reservation-icon.png'),
    IMG_EGG_NEW: require('../assets/images/Eggs-new.png'),
    IMG_DETAILS_ICON: require('../assets/images/details.png'),
    IMG_MAIL_ICON: require('../assets/images/mail_icon.png'),
    IMG_LOCATION_ICON: require('../assets/images/location_icon.png'),
    IMG_PHONE_ICON: require('../assets/images/phone_icon.png'),
    IMG_COLOR_CROSS: require('../assets/images/color-cross.png'),
    IMG_PLATE: require('../assets/images/error-plate.png'),
    IMG_INSTAGRAM: require('../assets/images/instagram.png'),
    IMG_CONDITION: require('../assets/images/bars-4.png'),
    IMG_GUEST: require('../assets/images/Guest.png'),
    IMG_TIME: require('../assets/images/Time.png'),
    IMG_SEATING: require('../assets/images/Seating.png'),
    IMG_ADD: require('../assets/images/Add.png'),
    IMG_MINUS: require('../assets/images/Minus.png'),
    IMG_RED_CROSS: require('../assets/images/red-cross.png'),
    IMG_RES_ADD: require('../assets/images/add-res.png'),
    IMG_ORDER: require('../assets/images/Orders.png'),
    IMG_BIKE: require('../assets/images/bike.png'),
    IMG_CELEBRATION: require('../assets/images/🎉.png'),
    IMG_STAR_FILLED: require('../assets/images/rating-star-filled.png'),
    IMG_STAR: require('../assets/images/rating-star.png'),
    IMG_SEATING_SYMBOL: require('../assets/images/seating_symbol.png'),
    IMG_FACEBOOK :require('../assets/images/facebook-logo.png'),
    IMG_TIKTOK: require('../assets/images/tiktok-logo.png'),
    IMG_DEPOSIT:require('../assets/images/deposit.png'),
    IMG_STAR_DARK_FILLED:require('../assets/images/star.png'),
    IMG_CHECK_PLEASE: require('../assets/images/checkPlease-icon.png'),
    IMG_CROWD_PLEASE_BG: require('../assets/images/crowdPlease_bg.png'),
    IMG_WHITE_STAR: require('../assets/images/white_star.png'),
    IMG_FOOTER_KNOCKER: require('../assets/images/footer-knocker.png'),
    IMG_FOOTER_PARTNER: require('../assets/images/footer-partner.png')
}
