import React, { FC, useEffect, useState } from 'react'
import Select from 'react-select';

import './select-input.scss'

type SelectInputProps = {
    label: string;
    selected: any;
    manualOptions: option[];
    type: 'seating' | 'seating_reserv';
    placeholder: string;
    onChange?: Function;
    index?: number;
    disabled?: boolean;
    formik: any;
}

interface option {
    value: string;
    label: string;
}

const SelectInput: FC<SelectInputProps> = (props) => {
    const { label, selected, manualOptions, type, placeholder, formik } = props;

    const [options, setOptions] = useState<Array<option>>([]);
    const [selecteOption, setSelecteOption] = useState<option | option[]>();

    useEffect(() => {
        switch (type) {
            case 'seating':
                const selectedSeating: option[] = [];
                manualOptions?.map(op => {
                    selected?.map((item: string) => {
                        if (op.value === item) {
                            selectedSeating.push(op);
                        }
                    })
                });
                setSelecteOption(selectedSeating);
                break;
            case 'seating_reserv':
                setSelecteOption(manualOptions?.find(op => op.value === selected));
                break;
        }
    }, [selected, manualOptions]);

    useEffect(() => {
        switch (type) {
            case 'seating':
                setOptions(manualOptions);
                break;

            case 'seating_reserv':
                setOptions(manualOptions);
                break;
        }
    }, [manualOptions])

    const onChange = (value: any) => {
        if (type === 'seating') {
            setSelecteOption(value);
            const category = value.map((item: option) => item.value);
            props.onChange && props.onChange(category, props.index);
        }
        else if (type === 'seating_reserv') {
            setSelecteOption(value)
            formik.setFieldValue(type, value.value, false);
            formik.setFieldError(type, undefined);
            props.onChange && props.onChange(value.value);
        }
        else {
            setSelecteOption(value)
            formik.setFieldValue(type, value.value, false);
            formik.setFieldError(type, undefined);
        }
    }

    return (
        <div className="search_bar select-item">
            {label}
            <Select
                isDisabled={props.disabled}
                value={selecteOption}
                placeholder={placeholder}
                options={options}
                onChange={(value: any) => onChange(value)}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: state.menuIsOpen ? '18px 18px 0 0' : '33px',
                        boxShadow: 'none',
                        background: state.isFocused ? 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box' : props.disabled ? '#c0cece6e' : '',
                        border: state.isFocused ? '1px solid transparent' : formik.errors.seating_reserv !== undefined ? '1px solid #ef4444' : '',
                        borderBottom: state.menuIsOpen ? 'none' : '',
                        "&:hover": {
                            background: props.disabled ? '#c0cece6e' : 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box',
                            border: '1px solid transparent'
                        },
                        minHeight: '36px',
                        cursor: props.disabled ? 'no-drop' : 'default',
                    }),
                    multiValue: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: 'transparent',
                        borderRadius: '12px',
                        border: '1px solid #c0cece',
                        marginTop: '2.5px',
                        marginLeft: '5px',
                        borderColor: '#01a8ac',
                        cursor: props.disabled ? 'no-drop' : 'default',
                        "&:hover": {
                            background: props.disabled ? '#c0cece6e' : 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box',
                            border: '1px solid transparent'
                        },
                    })
                }}
            />
        </div>
    )
}

export default SelectInput