import React, { FC, useEffect, useState } from "react";
import { Carousel } from "antd";

import ViewDetails from "./view-detail-modal/view-detail-modal";
import Slider from "react-slick";

import "./Menu-item.scss";
import { images } from "../../../commonConfig";

type MenuItemSectionProps = {
  obj: any;
  data: any;
  logoUrl: string;
  subCatItems: any[];
  index: number;
  logoImageProps: any;
  sponsorData: any;
};

const MenuItemSection: FC<MenuItemSectionProps> = (props) => {
  const { obj, data, logoUrl, subCatItems, index, sponsorData } = props;
  const [selectedSub, setSelectedSub] = useState<any>();
  const { logoImage } = props.logoImageProps;
  const settings = {
    infinite: false,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  const mergedItemAds = subCatItems?.map((data: any) => {
    sponsorData?.map((ads: any) =>
      ads.item_name === data.name
        ? (data = {
          ...data,
          itemSponsorBannerUrl: ads.sponser_media,
          sponser_url: ads.sponser_url,
        })
        : ""
    );
    return data;
  });

  return (
    <>
      <div className="img-caresoul-section" key={index}>
        {obj?.category && mergedItemAds.length !== 0 ? (
          <div className="img-caresoul-sub-category">
            <Carousel dots={true} arrows={true} >
              {
                <div>
                  <div className="soldOut-image-container">
                    <img
                      src={
                        selectedSub?.itemCoverImageUrl !== ""
                          ? selectedSub?.itemCoverImageUrl
                          : data?.resData.companyLogoImage ? data?.resData.companyLogoImage : images.IMG_DEFAULT_LOGO
                      }
                      alt="icon"
                      className="caresoul-img"
                    />
                  </div>
                </div>
              }
              {selectedSub?.itemVideoUrl && (
                <div>
                  <video
                    src={selectedSub.itemVideoUrl}
                    controls={true}
                    className="caresoul-img"
                    autoPlay={false}
                  />
                </div>
              )}
              {selectedSub?.itemSponsorBannerUrl && (
                <div>
                  <a href={selectedSub.sponser_url} target="_blank">
                    <img
                      src={selectedSub.itemSponsorBannerUrl}
                      alt="icon"
                      className="caresoul-img"
                    />
                  </a>
                </div>
              )}

            </Carousel>
          </div>
        ) : (
          <Carousel dots={true}>
            {obj.itemCoverImageUrl || obj?.coverImageURL ? (
              <div className="soldOut-image-container">
                {obj.itemCoverImageUrl && (
                  <img
                    src={
                      obj.itemCoverImageUrl
                        ? obj.itemCoverImageUrl
                        : data.resData.companyLogoImage ? data?.resData.companyLogoImage : images.IMG_DEFAULT_LOGO
                    }
                    alt="icon"
                    className="caresoul-img"
                  />
                )}
                {obj?.coverImageURL && (
                  <img
                    src={
                      obj?.coverImageURL
                        ? obj?.coverImageURL
                        : data.resData.companyLogoImage
                    }
                    alt="icon"
                    className="caresoul-img"
                  />
                )}
                {obj.sold_out && (
                  <div className="sold-out-text" style={{ top: "4px" }}>
                    {obj.sold_out && "SOLD OUT"}
                  </div>
                )}

              </div>
            ) : (
              <div>
                <img
                  src={
                    data.resData.companyLogoImage
                      ? data.resData.companyLogoImage
                      : logoImage
                        ? logoImage
                        : images.IMG_DEFAULT_LOGO
                  }
                  alt="icon"
                  className="caresoul-img"
                />
              </div>
            )}
            {obj.itemVideoUrl && (
              <video
                src={obj.itemVideoUrl}
                controls={true}
                className="caresoul-img"
                autoPlay={false}
              />
            )}
            {obj.itemSponsorBannerUrl && (
              <a href={obj.sponser_url} target="_blank">
                <img
                  src={logoUrl ? logoUrl : obj.itemSponsorBannerUrl}
                  alt="icon"
                  className="caresoul-img"
                />
              </a>
            )}
          </Carousel>
        )}
        {/* {<Carousel dots={true} >
                {(obj.itemCoverImageUrl || obj?.coverImageURL)
                    ? <div className='soldOut-image-container'>
                        {obj.itemCoverImageUrl && <img src={obj.itemCoverImageUrl ? obj.itemCoverImageUrl : data.resData.companyLogoImage}
                            alt='icon'
                            className='caresoul-img'
                        />}
                        {obj?.coverImageURL && <img src={obj?.coverImageURL ? obj?.coverImageURL : data.resData.companyLogoImage}
                            alt='icon'
                            className='caresoul-img'
                        />}
                        {obj.sold_out && <div className='sold-out-text' style={{ top: "4px" }}>{obj.sold_out && "SOLD OUT"}</div>}
                    </div>
                    : <div>
                        <img src={data.resData.companyLogoImage ? data.resData.companyLogoImage : logoImage
                            ? logoImage : images.IMG_DEFAULT_LOGO}
                            alt='icon'
                            className='caresoul-img'
                        />
                    </div>}
                {obj.itemVideoUrl
                    && <video
                        src={obj.itemVideoUrl}
                        controls={true}
                        className='caresoul-img'
                        autoPlay={false}
                    />}
                {obj.itemSponsorBannerUrl
                    && <a href={obj.sponser_url} target="_blank">
                        <img
                            src={logoUrl ? logoUrl : obj.itemSponsorBannerUrl}
                            alt='icon'
                            className='caresoul-img'
                        />
                    </a>}
            </Carousel>} */}
        {obj?.category && mergedItemAds.length !== 0 && (
          <div className="menu-item-slider">
            <Slider {...settings}>
              {mergedItemAds.map((ele, inx) => {
                if (!selectedSub) {
                  setSelectedSub(mergedItemAds[0]);
                }
                return (
                  <div key={inx} onClick={() => setSelectedSub(ele)}>
                    <div
                      className="menu-body-text"
                      style={
                        selectedSub?.name === ele?.name
                          ? { borderBottom: "2px solid" }
                          : {}
                      }
                    >
                      {ele.name}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        )}
      </div>

      <div className="details-section">
        <h1 className="name-resData">{obj.name}</h1>
        <p className="sub-section">{obj.description}</p>
        {obj?.price && (
          <div className="details-time-section">
            <p className="name-price">
              {obj?.price && `$${obj.price} ${data.resData.currency}`}
            </p>
          </div>
        )}
      </div>
      {selectedSub && (
        <div className="details-section">
          <h1 className="name-resData">{selectedSub.name}</h1>
          <p className="sub-section">{selectedSub.description}</p>
          <div className="details-time-section">
            <p className="name-price">
              {selectedSub?.price &&
                `$${selectedSub.price} ${data.resData.currency}`}
            </p>
          </div>
        </div>
      )}
      {obj?.dietary && obj.dietary.length ? <div className="details-dietary">
        {obj?.dietary && obj.dietary.length ? obj.dietary.map((item: any) => (
          <div className="dietary-name">{item}</div>
        )) : selectedSub?.dietary && selectedSub?.dietary.length ? selectedSub?.dietary.map((item: any) => (
          <div className="dietary-name">{item}</div>
        )) : ''}
      </div> : ""}
      {selectedSub ? (
        <div className={selectedSub?.rate ? "feedback-container-mobile" : "feedback-details-mobile"}>
          {selectedSub?.rate ? <div className='feedback-text-homepage' style={{ paddingBottom: "15px" }}>
            <img src={images.IMG_STAR_DARK_FILLED} className="feedback-star-home" /><div>{selectedSub?.rate}</div>
          </div> : ""}
          <ViewDetails data={selectedSub} screen="mobile" />
        </div>
      ) : (
        <div className={obj?.rate ? "feedback-container-mobile" : "feedback-details-mobile"}>
          {obj?.rate ? <div className='feedback-text-homepage' style={{ paddingBottom: "15px" }}>
            <img src={images.IMG_STAR_DARK_FILLED} className="feedback-star-home" /><div>{obj?.rate}</div>
          </div> : ""}
          <ViewDetails data={obj} screen="mobile" />
        </div>
      )}
    </>
  );
};

export default MenuItemSection;
