import React, { FC, useState } from 'react';
import { images } from '../../../../commonConfig';
import './view-detail-modal.scss';
import { VscChromeClose } from 'react-icons/vsc'

type ViewDetailsProps = {
    data: any;
    screen: 'mobile' | 'desktop';
}
const ViewDetails: FC<ViewDetailsProps> = ({ data, screen }) => {
    const [showDetails, setShowDetails] = useState(false)
    let addOns = [];
    let sizes = [];
    let options = [];
    // let customModifiers = [];

    if (data.modifiers) {
        if (data.modifiers?.addOns.length !== 0) {
            addOns = data.modifiers?.addOns;
        }
        if (data.modifiers?.sizes.length !== 0) {
            sizes = data.modifiers?.sizes;
        }
        if (data.modifiers?.options.length !== 0) {
            options = data.modifiers?.options;
        }
        // if (data.modifiers?.customModifiers) {
        //     customModifiers = data.modifiers?.customModifiers;
        // }
    }
    return (<>
        {(addOns?.length > 0 || sizes?.length > 0 || options?.length > 0 || data.spice_level || data.prep_time || data.modifiers?.customModifiers)
            ? <>
                <div onClick={() => setShowDetails(true)} className={screen === 'desktop' ? "view-details-desktop" : 'view-details'}>
                    <span>View Details
                    </span>
                </div>

                {showDetails && <>
                    <div className="modal-main-content show">
                        <div className='border-top-mobile'></div>
                        <div className="viewDetails-header">
                            <div onClick={() => setShowDetails(false)} className="btn-modal close-button button" style={{ marginTop: "0px" }}> <VscChromeClose /></div>
                        </div>
                        <div className='border-left'></div>
                        <div className='view-details-container'>
                            <div className='view-details-subcontainer'>
                                {(data?.spice_level || options?.length > 0 || data?.modifiers?.customModifiers)
                                    ?
                                    <div className='size-add-on-spice'>
                                        <div className='wing-sauce-container'>
                                            {options.length > 0 && <div className='view-details-heading' style={{ textAlign: "left" }}>Options</div>}
                                            {options.map((ele: any) => (<p style={{ textAlign: "left" }}>{ele.option}</p>))}
                                        </div>
                                        <div className="view-details-subheading">
                                            {/* {data?.modifiers?.customModifiers && <div className='view-details-heading' style={{ textAlign: "left" }}></div>} */}
                                            {data?.modifiers?.customModifiers?.map((customModifier: any) => (
                                                <div className='modifier-items-main-container'>
                                                    <div key={customModifier?.modifierName} className='sizes-container-custom' style={{ justifyContent: "left", gap: "15px" }}>
                                                        <div className='view-details-heading' style={{ textAlign: "left" }}>
                                                            {customModifier?.modifierName}
                                                        </div>
                                                        <div className='modifier-items-container'>
                                                            {customModifier?.modifierItems?.map((item: any) => (
                                                                <div key={item.modifyIndex} className='modifier-item'>
                                                                    <p style={{ textAlign: "left" }}>{item.item}</p>
                                                                    {item.price && <p style={{ textAlign: "left", color: "#546b6b", fontWeight: "400" }}>${item?.price}.00</p>}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{ marginBottom: "-5px" }}>
                                            {data.spice_level > 0 && <div className='view-details-heading spice-heading'>Spice Level </div>}
                                            {data.spice_level > 0 && <div className="spice-container">
                                                <button className="button spicy-icon" >
                                                    <div style={{ color: '#01a8ac', display: "flex", gap: "8px" }} >
                                                        <img src={data.spice_level > 0 ? images.IMG_SPICE_COLOR : images.IMG_SPICE} className="img_spice" />
                                                        <img src={data.spice_level > 1 ? images.IMG_SPICE_COLOR : images.IMG_SPICE} className="img_spice" />
                                                        <img src={data.spice_level > 2 ? images.IMG_SPICE_COLOR : images.IMG_SPICE} className="img_spice" />
                                                        <img src={data.spice_level > 3 ? images.IMG_SPICE_COLOR : images.IMG_SPICE} className="img_spice" />
                                                    </div>
                                                </button>
                                                <div className="spice-text">
                                                    {data.spice_level > 3 ? "Extra hot" : data.spice_level > 2 ? "Hot" : data.spice_level > 1 ? "Medium " : "Mild"}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    : ''}
                                {(sizes?.length > 0 || addOns?.length > 0 || data.prep_time > 0)
                                    ? <div style={{ display: "flex" }}>
                                        <div className='size-add-on'>
                                            <div>
                                                <div className="view-details-subheading">
                                                    {sizes.length > 0 && <div className='view-details-heading'>Sizes</div>}
                                                    {sizes?.map((ele: any) => (
                                                        <div className='sizes-container'>
                                                            <div className='view-detail-name-heading' >
                                                                <p>{ele.size}</p>
                                                            </div>
                                                            <div className='view-details-price'>
                                                                <p>${ele.price}.00</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="view-details-subheading"> {addOns.length > 0 && <div className='view-details-heading'>Add Ons</div>}
                                                    {addOns.map((ele: any) => (
                                                        <div className='sizes-container'>
                                                            <div className='view-detail-name-heading' >
                                                                <p>{ele.item}</p>
                                                            </div>
                                                            <div className='view-details-price'>
                                                                <p>${ele.price}.00</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {data.prep_time && <div style={{ marginBottom: "24px" }}>
                                                <div className='view-details-heading'>   Prep time </div>
                                                <p className='prep-time'>{data.prep_time} minutes</p>
                                            </div>}
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </>}</>
            : ""}
    </>)
}
export default ViewDetails;