import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useFormik } from "formik";
import * as yup from 'yup';

import { days, images, isBetween, months, parseTime, sendReservationMailToAdmin, sendReservationMailToDiner, sendReservationSMSToAdmin, sendReservationSMSToDinerPlivo, sentReservationSMSToDiner, timePicker } from '../../../commonConfig';
import Footer from '../../../components/molecules/Footer/Footer';
import SelectInputTime from '../../../components/atoms/select-input-time/select-input-time';
import SelectInput from '../../../components/atoms/select-input/select-input';
import ReserveTable from '../reserve-table/reserve-table';
import { db } from '../../../firebase/firebase';
import './make-reservation.scss'
import moment from 'moment';
import { Modal } from 'antd';
import { isMobile, isAndroid, } from 'react-device-detect'
type MakeReservationProps = {
    restaurantData: any;
    membersLimit: any;
    date: any;
    isReserveEnabled: boolean;
    seatingList: any[];
    resAdmins: any;
    resHosts:any;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setSeatingList: React.Dispatch<React.SetStateAction<any[]>>;
    defaultSeating: any;
}

const MakeReservation: FC<MakeReservationProps> = ({ resAdmins, restaurantData, membersLimit, date, isReserveEnabled, seatingList, setLoading, setSeatingList, defaultSeating, resHosts }) => {
    const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
    const [displayResDate, setDisplayResDate] = useState<any>();
    const [reserveTableScreen, setReserveTableScreen] = useState<boolean>(false);
    const [specificDays, setSpecificDays] = useState<any>([])
    const [blockoutDays, setBlockoutDays] = useState<any>([])
    const [restrictTime, setRestrictTime] = useState<any>([]);
    const [defaultTimePicker, setDefaultTimePicker] = useState<any[]>([])
    const [showPayModal, setShowPayModal] = useState<boolean>(false)

    const urlParams = useParams()
    const uniqueID = urlParams.uniqId
    const navigate = useNavigate();

    useEffect(() => {
        if (restaurantData) {
            setRestrictedDays()
            // setDefaultSeatingList(seatingList)
        }
    }, [restaurantData]);

    const setRestrictedDays = () => {
        setSpecificDays(restaurantData?.reservation_settings?.restrictDays
            ? restaurantData?.reservation_settings?.restrictDays
            : []);
        setBlockoutDays(restaurantData?.reservation_settings?.blockoutDays
            ? restaurantData?.reservation_settings?.blockoutDays
            : []);
        setRestrictTime(restaurantData?.reservation_settings?.restrictTime
            ? restaurantData?.reservation_settings?.restrictTime
            : []);
    }

    const formik = useFormik({
        initialValues: {
            date: '',
            deposit: '0',
            dietary: [],
            email: '',
            first_name: '',
            // id: "blw2PX2kKAVDGpq2yNX2",
            last_name: '',
            members: 1,
            occasion: [],
            phone: '',
            // restaurantId: "NHAe7e4GfG36ldHWr1sh",
            seating_reserv: '',
            source: 'Other',
            specialRequest: '',
            status: '',
            time: '',
            // userId: '',
        },
        validationSchema: yup.object().shape({
            first_name: yup.string().required('This is a required field.'),
            last_name: yup.string().required('This is a required field.'),
            email: yup.string().required('This is a required field.'),
            seating_reserv: yup.string().required('This is a required field.'),
            time: yup.string().required('This is a required field.'),
            phone: yup.number().required('This is a required field.'),
            date: yup.date().required('This is a required field.'),
            members: yup.number().min(2, 'Minimum 2 Guests Required.').required('This is a required field.')
        }),
        onSubmit: () => {
            setReserveTableScreen(true)
        }
    })

    var currentDate: any, currentDayNum: any, currentDay: any
    useEffect(() => {
        //Get Current Date and Current Day;

        var tempToday = moment(new Date()).format('MM/DD/YYYY');
        var selectedDateDay = formik.values.date ? moment(formik.values.date).format('dddd') : tempToday;
        console.log(selectedDateDay)
        var cutOffDays = [];
    
        if (selectedDateDay) {
          cutOffDays = restrictTime?.filter(
            (item: any) => item?.days && item.days.includes(selectedDateDay)
          );
        }
    
        const hoursObj = restaurantData.hoursOperation?.filter((item: any) =>
          item.days.includes(selectedDateDay)
        );
        const openTime = hoursObj[0]?.openTime;
        const closeTime = hoursObj[0]?.closeTime;
        var timeSlots = [];
    
        if ((formik.values.date || tempToday) && openTime && closeTime) {
          const openTimeMoment = moment(openTime, 'hh:mm A');
          const closeTimeMoment = moment(closeTime, 'hh:mm A');
    
          if (closeTimeMoment.isBefore(openTimeMoment)) {
            closeTimeMoment.add(1, 'day'); // Add a day to the closing time
          }
    
          while (openTimeMoment.isBefore(closeTimeMoment)) {
            timeSlots.push({
              label: openTimeMoment.format('hh:mm A'),
              value: openTimeMoment.format('hh:mm A'),
            });
            openTimeMoment.add(15, 'minutes');
          }
        } else if ((formik.values.date || tempToday) && openTime === undefined && closeTime === undefined) {
          const openTimeMoment = moment('12:00 AM', 'hh:mm A');
          const closeTimeMoment = moment('11:45 PM', 'hh:mm A');
    
          while (openTimeMoment.isBefore(closeTimeMoment)) {
            timeSlots.push({
              label: openTimeMoment.format('hh:mm A'),
              value: openTimeMoment.format('hh:mm A'),
            });
            openTimeMoment.add(15, 'minutes');
          }
        }
    
        const parseTime = (timeStr: any) => {
          const [time, meridiem] = timeStr.split(" ");
          const [hours, minutes] = time.split(":");
          let hours24 = parseInt(hours, 10) + (meridiem === "PM" && hours !== "12" ? 12 : 0);
          if (meridiem === "AM" && hours === "12") {
            hours24 = 0; // Handle midnight case
          }
          return { hours: hours24, minutes: parseInt(minutes, 10) };
        };
    
        const isBetween = (time: any, start: any, end: any) => {
          // Check if the end time is on the next day
          if (end.hours < start.hours || (end.hours === start.hours && end.minutes < start.minutes)) {
            return (
              (time.hours > start.hours || (time.hours === start.hours && time.minutes >= start.minutes)) ||
              (time.hours < end.hours || (time.hours === end.hours && time.minutes <= end.minutes))
            );
          }
          return (
            (time.hours > start.hours || (time.hours === start.hours && time.minutes >= start.minutes)) &&
            (time.hours < end.hours || (time.hours === end.hours && time.minutes <= end.minutes))
          );
        };
    
        var filteredTimePicker = timeSlots;
    
        if (cutOffDays?.length) {
          cutOffDays.forEach((cutOff: any) => {
            const startTime = cutOff.startTime;
            const endTime = cutOff.endTime;
            const startTimeObj = parseTime(startTime);
            const endTimeObj = parseTime(endTime);
    
            filteredTimePicker = filteredTimePicker.filter(
              ({ value }) => !isBetween(parseTime(value), startTimeObj, endTimeObj)
            );
          });
        }
    
        setDefaultTimePicker(filteredTimePicker);
    
    }, [formik.values.date]);

    // useEffect(() => {
    //     if (cutoffTimeWithSeat?.length) {
    //         const { startTime, endTime, seatingType } = cutoffTimeWithSeat[0];
    //         const startTimeObj = parseTime(startTime);
    //         const endTimeObj = parseTime(endTime);
    //         const selectedSlot = defaultTimePicker?.some(({ value }) => {
    //             const currentTime = parseTime(value);
    //             return isBetween(currentTime, startTimeObj, endTimeObj) && value === formik.values.time;
    //         });
    //         setSeatingList(selectedSlot ? seatingList?.filter(seating => !seatingType.includes(seating.value)) : cutOffSeat);

    //         if (formik.values.seating_reserv) {
    //             formik.setFieldValue('seating_reserv', '', false);
    //         }
    //     }
    // }, [formik.values.time])

    // const handleCutoffTime = () => {
    //     var cutOffDaysWithoutSeating = restrictTime.filter((item: any) => item?.days && !item?.seatingType.length && item.days.includes(currentDay));
    //     setCutoffTimeListWithoutSeating(cutOffDaysWithoutSeating)
    //     var cutOffDaysWithSeat = restrictTime.filter((item: any) => item?.days && item?.seatingType.length && item.days.includes(currentDay));
    //     setCutoffTimeWithSeat(cutOffDaysWithSeat);

    // }

    const handleChange = (e: any, field: any) => {
        if (field === 'phone') {
            formik.setFieldValue(field, `+${e} `, false);
            formik.setFieldError(field, undefined)
            // setFlag(true)
        } else if (field === 'date') {
            const date = e.getDate()
            const monthName = months[e.getMonth()]
            const dayName = days[e.getDay()] // Thu
            const formatted = `${dayName}, ${monthName} ${date}`
            setDisplayResDate(formatted.toString());
            formik.setFieldValue(field, e.toDateString(), false);
            formik.setFieldError(field, undefined);
            setDisplayCalendar(false)
        } else {
            formik.setFieldValue(field, e.target.value, false);
            formik.setFieldError(field, undefined);
        }
    }

    const onAddGuest = () => {
        if (membersLimit !== undefined && membersLimit !== 0) {
            const value = formik.values.members < membersLimit ? formik.values.members + 1 : formik.values.members
            formik.setFieldValue('members', value, false);
        } else {
            formik.setFieldValue('members', formik.values.members + 1, false);
        }
    }

    const onMinusGuest = () => {
        formik.setFieldValue('members', formik.values.members - 1, false);
    }

    const handleSubmit = async () => {
        setLoading(true);
        const docRef = db.collection('_').doc()
        const data = {
            id: docRef.id,
            ...formik.values,
            userId: '',
            restaurantId: restaurantData.id,
            status: 'Pending',
            source:'visuEatsWeb'
        }

        await db.collection('Reservation')
            .doc(docRef.id)
            .set(data)
            .then((res) => {
                const subject = "You have a new reservation 🥳"
                sendReservationMailToDiner(docRef.id, formik.values, restaurantData, subject)
                sendReservationMailToAdmin(resAdmins, formik.values, restaurantData, resHosts);
                sendReservationSMSToDinerPlivo(formik.values, restaurantData, 'Pending');
                sendReservationSMSToAdmin(formik.values, restaurantData, 'Pending');
                console.log('restaurantData.time in reservation :>> ', restaurantData.time, restaurantData.date);
                navigate(`/${uniqueID}/reservation-reschedule-confirmed`, {
                    state: { reservationData: formik.values, restaurantData, date: displayResDate }
                })
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                // setErrorModal(true);
            })
    }

    const handleSeating = (value: any) => {
        defaultSeating.map((seating: any) => {
            if (seating.seatingType === value) {
                formik.setFieldValue('deposit', seating.deposit ? seating.amount : '0', false);
                formik.setFieldError('deposit', undefined);
            }
        })
    }

    const tileDisabled = (calendarDate: any) => {
        const currentDate = moment();
        const blockoutList = blockoutDays
            // ?.filter((item: any) => !item.seating)
            .map((item: any) => item.days)
            .flat();
        const blockoutDateResult = [];
        while (currentDate <= date.maxDate) {
            const dayName = moment(currentDate).format('dddd');
            if (blockoutList?.includes(dayName)) {
                blockoutDateResult.push(moment(currentDate).format('YYYY-MM-DD'));
            }
            currentDate.add(1, 'day');
        }

        const specificDayList = specificDays
            // ?.filter((item: any) => !item.seating)
            .map((item: any) =>
                item.days.map((timestamp: any) => moment(timestamp).format('YYYY-MM-DD')),
            )
            .flat();

        var daysWithoutSeating = [
            ...new Set(blockoutDateResult.concat(specificDayList)),
        ];
        var disableDate = moment(calendarDate.date).format('YYYY-MM-DD')
        if (daysWithoutSeating.includes(disableDate)) {
            return true;
        }
        return false;
    };


    return (
        <>
            {isReserveEnabled ? <>
                {reserveTableScreen ?
                    <ReserveTable formik={formik} restaurantData={restaurantData} displayResDate={displayResDate} handleFormSubmit={handleSubmit} />
                    : <div>
                        <div className="schedule-header">Make a reservation</div>
                        <div className="schedule-flex">
                            <div className="schedule-sub-header">Reserve a Table</div>
                        </div>
                        <div >
                            {window.innerWidth > 500 ? <div className="schedule-row">
                                <div className="schedule-input-container">
                                    <div className="schedule-label">Select a Date*</div>
                                    <div style={{ width: "auto", position: "relative" }}>
                                        <div>
                                            <div className='schedule-product-icon'>
                                                <img src={images.IMG_RESERVE} className="schedule-product-icon-img" alt="#" />
                                            </div>
                                            <div className="schedule-reservation-bar" >
                                                <input
                                                    className={formik.errors?.date !== undefined ? 'search-input-error' : "search-input"}
                                                    placeholder="Date"
                                                    type="any"
                                                    id="date"
                                                    autoComplete='off'
                                                    // disabled={scheduledAgain ? true : false}
                                                    value={displayResDate}
                                                    name="date"
                                                    onClick={() => { setDisplayCalendar(!displayCalendar) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {displayCalendar ?
                                        <div className="scheduled-calendar">
                                            <Calendar calendarType="US" showNeighboringMonth={false}
                                                value={formik.values.date}
                                                defaultValue={formik.values.date}
                                                onChange={(e: any) => handleChange(e, 'date')}
                                                minDate={date.minDate}
                                                maxDate={date.maxDate}
                                                tileDisabled={tileDisabled}
                                            />
                                        </div>
                                        : ""}
                                    {formik.errors.date && <p className="error-text">This is a required field.</p>}
                                </div>

                                <div className="schedule-input-container">
                                    <div className="schedule-label">Select a Seating*</div>
                                    <div style={{ position: 'relative' }}>
                                        <div className='schedule-product-icon' style={{ zIndex: 1 }}>
                                            <img src={images.IMG_SEATING} className="schedule-product-icon-img" alt="#" />
                                        </div>
                                        <div style={{ width: "auto" }}>
                                            <SelectInput
                                                label=""
                                                selected={formik.values?.seating_reserv}
                                                placeholder="Select a seating"
                                                type="seating_reserv"
                                                formik={formik}
                                                manualOptions={seatingList}
                                                onChange={handleSeating}
                                            // disabled={scheduledAgain ? true : false}
                                            />
                                        </div>
                                    </div>
                                    {formik.errors.seating_reserv && <p className="error-text">{formik.errors.seating_reserv}</p>}
                                </div>

                                <div className="schedule-input-container">
                                    <div className="schedule-label">Email*</div>
                                    <div style={{ width: "auto", position: "relative" }}>
                                        <input
                                            style={{ paddingLeft: '15px' }}
                                            className={formik.errors.email !== undefined ? 'search-input-error' : "search-input"}
                                            placeholder=""
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={(e) => handleChange(e, 'email')}
                                        // disabled={scheduledAgain ? true : false}
                                        />
                                    </div>
                                    {formik.errors.email && <p className="error-text">{formik.errors.email}</p>}
                                </div>


                                <div className="schedule-input-container">
                                    <div className="schedule-label">Select a Time*</div>
                                    <div style={{ width: "auto" }}>
                                        <div style={{ position: "relative" }}>
                                            <div className='schedule-product-icon' style={{ zIndex: "1" }}>
                                                <img alt="#" src={images.IMG_TIME} className="schedule-product-icon-img" />
                                            </div>
                                            <SelectInputTime
                                                id='time'
                                                selected={formik.values.time}
                                                placeholder="Time"
                                                type="time"
                                                formik={formik}
                                                manualOptions={defaultTimePicker}
                                            />

                                        </div>
                                    </div>
                                    {formik.errors.time && <p className="error-text">{formik.errors.time}</p>}
                                </div>
                                <div className="schedule-input-container">
                                    <div className="schedule-label">Deposit</div>
                                    <div style={{ position: 'relative' }}>
                                        <div style={{ width: "auto" }}>
                                            <input
                                                style={{ paddingLeft: '15px' }}
                                                className={formik.errors?.deposit !== undefined ? 'search-input-error' : "search-input"}
                                                placeholder=""
                                                type="deposit"
                                                id="deposit"
                                                name="deposit"
                                                value={formik.values.deposit}
                                                disabled={true}
                                            // onChange={(e: any) => handleChange(e, 'first_name')}
                                            // disabled={scheduledAgain ? true : false}
                                            />
                                        </div>
                                    </div>
                                    {formik.errors.seating_reserv && <p className="error-text">{formik.errors.seating_reserv}</p>}
                                </div>
                                <div className="schedule-input-container">
                                    <div className="schedule-label">First Name*</div>
                                    <div style={{ width: "auto" }}>
                                        <input
                                            style={{ paddingLeft: '15px' }}
                                            className={formik.errors?.first_name !== undefined ? 'search-input-error' : "search-input"}
                                            placeholder=""
                                            type="name"
                                            id="first_name"
                                            name="type"
                                            value={formik.values.first_name}
                                            onChange={(e: any) => handleChange(e, 'first_name')}
                                        // disabled={scheduledAgain ? true : false}
                                        />
                                    </div>
                                    {formik.errors.first_name && <p className="error-text">{formik.errors.first_name}</p>}
                                </div>

                                <div className="schedule-input-container">
                                    <div className="schedule-label">Party Size*</div>
                                    <div style={{ width: "auto" }} className="party-size-container">
                                        <div style={{ position: "relative" }}>
                                            <div className='schedule-product-icon'>
                                                <img alt="#" src={images.IMG_GUEST} className="schedule-product-icon-img" />
                                            </div>
                                            <div className="reservation_bar">
                                                <input
                                                    className={formik.errors?.members !== undefined && formik.values.members <= 1 ? 'search-input-error' : "search-input"}
                                                    placeholder="Party Size"
                                                    name="members"
                                                    type="any"
                                                    id="members"
                                                    value={formik.values.members === 1 ? 'Party Size' : `${formik.values.members} guests`}
                                                    onChange={(e: any) => handleChange(e, 'members')}
                                                    onWheel={(e: any) => e.target.blur()}
                                                />
                                            </div>
                                            <div className="add-sign" onClick={() => { onAddGuest() }} >
                                                <img alt="#" src={images.IMG_ADD} className="add-sign-img" />
                                            </div>
                                            <div className="minus-sign" onClick={() => formik.values.members > 2 ? onMinusGuest() : ''}>
                                                <img alt="#" src={images.IMG_MINUS} className="minus-sign-img" />
                                            </div>
                                        </div>
                                        {membersLimit <= formik.values.members && membersLimit !== 0 && <p className="error-text" style={{ marginTop: '3px' }}>A maximum of {membersLimit} guests are allowed.</p>}
                                        {formik.errors.members && formik.values.members <= 1 && <p className="error-text">{formik.errors.members}</p>}
                                    </div>
                                </div>
                                <div className="schedule-input-container">
                                    <div className="schedule-label">Phone Number*</div>
                                    <div style={{ width: "auto" }}>
                                        <PhoneInput containerStyle={{ width: 'auto' }}
                                            placeholder="Enter your phone number"
                                            inputStyle={{
                                                borderRadius: '30px',
                                                border: formik.errors.phone !== undefined ? '1px solid #ef4444' : '1px solid #c0cece',
                                                // color: scheduledAgain ?  'hsl(0, 0%, 60%) !important' :''
                                                height: "51px"
                                            }}
                                            enableTerritories={true}
                                            country={"jm"}
                                            enableSearch={true}
                                            value={formik.values.phone}
                                            onChange={(e: any) => handleChange(e, 'phone')}
                                            autoFormat={false}
                                        // disabled={scheduledAgain ? true : false}
                                        />
                                    </div>
                                    {formik.errors.phone && <p className="error-text">{formik.errors.phone}</p>}
                                </div>


                                <div className="schedule-input-container">
                                    <div className="schedule-label">Last Name*</div>
                                    <div style={{ width: "auto" }}>
                                        <input
                                            style={{ paddingLeft: '15px' }}
                                            className={formik.errors?.last_name !== undefined ? 'search-input-error' : "search-input"}
                                            placeholder=""
                                            type="name"
                                            id="last_name"
                                            name="type"
                                            value={formik.values.last_name}
                                            onChange={(e: any) => handleChange(e, 'last_name')}
                                        // disabled={scheduledAgain ? true : false}
                                        />
                                    </div>
                                    {formik.errors.last_name && <p className="error-text">{formik.errors.last_name}</p>}
                                </div>

                            </div >
                                :
                                <div className="schedule-row">
                                    <div className="schedule-input-container">
                                        <div style={{ width: "auto", position: "relative" }}>
                                            <div>
                                                <div className='schedule-product-icon'>
                                                    <img src={images.IMG_RESERVE} className="schedule-product-icon-img" alt="#" />
                                                </div>
                                                <div className="schedule-reservation-bar" >
                                                    <input
                                                        className={formik.errors?.date !== undefined ? 'search-input-error' : "search-input"}
                                                        placeholder="Date"
                                                        type="any"
                                                        id="date"
                                                        // disabled={scheduledAgain ? true : false}
                                                        value={displayResDate}
                                                        name="date"
                                                        onClick={() => { setDisplayCalendar(!displayCalendar) }}
                                                        autoComplete='off'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {displayCalendar ?
                                            <div className="scheduled-calendar">
                                                <Calendar calendarType="US" showNeighboringMonth={false}
                                                    value={formik.values.date}
                                                    defaultValue={formik.values.date}
                                                    onChange={(e: any) => handleChange(e, 'date')}
                                                    minDate={date.minDate}
                                                    maxDate={date.maxDate}
                                                    tileDisabled={tileDisabled}
                                                />
                                            </div>
                                            : ""}
                                        {formik.errors.date && <p className="error-text">This is a required field.</p>}
                                    </div>
                                    <div className="schedule-input-container">
                                        <div style={{ width: "auto" }}>
                                            <div style={{ position: "relative" }}>
                                                <div className='schedule-product-icon' style={{ zIndex: "1" }}>
                                                    <img alt="#" src={images.IMG_TIME} className="schedule-product-icon-img" />
                                                </div>
                                                <SelectInputTime
                                                    id='time'
                                                    selected={formik.values?.time}
                                                    placeholder="Time"
                                                    type="time"
                                                    formik={formik}
                                                    manualOptions={defaultTimePicker}
                                                // disabled={scheduledAgain ? true : false}
                                                />
                                            </div>
                                            {formik.errors.time && <p className="error-text">{formik.errors.time}</p>}
                                        </div>

                                    </div>
                                    <div className="schedule-input-container">
                                        <div style={{ width: "auto" }} className="party-size-container">
                                            <div style={{ position: "relative" }}>
                                                <div className='schedule-product-icon'>
                                                    <img alt="#" src={images.IMG_GUEST} className="schedule-product-icon-img" />
                                                </div>
                                                <div className="reservation_bar">
                                                    <input
                                                        className={formik.errors?.members !== undefined && formik.values.members <= 1 ? 'search-input-error' : "search-input"}
                                                        placeholder="Party Size"
                                                        name="members"
                                                        type="any"
                                                        id="members"
                                                        value={formik.values.members === 1 ? 'Party Size' : `${formik.values.members} guests`}
                                                        onChange={(e: any) => handleChange(e, 'members')}
                                                        onWheel={(e: any) => e.target.blur()}
                                                    />
                                                </div>
                                                <div className="add-sign" onClick={() => { onAddGuest() }} >
                                                    <img alt="#" src={images.IMG_ADD} className="add-sign-img" />
                                                </div>
                                                <div className="minus-sign" onClick={() => formik.values.members > 2 ? onMinusGuest() : ''}>
                                                    <img alt="#" src={images.IMG_MINUS} className="minus-sign-img" />
                                                </div>
                                            </div>
                                            {membersLimit <= formik.values.members && membersLimit !== 0 && <p className="error-text" style={{ marginTop: '3px' }}>A maximum of {membersLimit} guests are allowed.</p>}
                                            {formik.errors.members && formik.values.members <= 1 && <p className="error-text">{formik.errors.members}</p>}

                                        </div>
                                    </div>
                                    <div className="schedule-input-container">
                                        <div className="schedule-label">Select a Seating*</div>
                                        <div style={{ position: 'relative' }}>
                                            <div className='schedule-product-icon' style={{ zIndex: 1 }}>
                                                <img src={images.IMG_SEATING_SYMBOL} className="schedule-seating-icon-img" alt="#" />
                                            </div>
                                            <div style={{ width: "auto" }}>
                                                <SelectInput
                                                    label=""
                                                    selected={formik.values?.seating_reserv}
                                                    placeholder="Select a seating"
                                                    type="seating_reserv"
                                                    formik={formik}
                                                    manualOptions={seatingList}
                                                    onChange={handleSeating}
                                                // disabled={scheduledAgain ? true : false}
                                                />
                                            </div>
                                        </div>
                                        {formik.errors.seating_reserv && <p className="error-text">{formik.errors.seating_reserv}</p>}
                                    </div>
                                    <div className="schedule-input-container">
                                        <div className="schedule-label">Deposit</div>
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ width: "auto" }}>
                                                <input
                                                    style={{ paddingLeft: '15px' }}
                                                    className={formik.errors?.deposit !== undefined ? 'search-input-error' : "search-input"}
                                                    placeholder=""
                                                    type="deposit"
                                                    id="deposit"
                                                    name="deposit"
                                                    value={formik.values.deposit}
                                                    disabled={true}
                                                // onChange={(e: any) => handleChange(e, 'first_name')}
                                                // disabled={scheduledAgain ? true : false}
                                                />
                                            </div>
                                        </div>
                                        {formik.errors.seating_reserv && <p className="error-text">{formik.errors.seating_reserv}</p>}
                                    </div>

                                    <div className="schedule-contact-label">Contact Details</div>
                                    <div className="schedule-input-container">
                                        <div className="schedule-label">First Name*</div>
                                        <div style={{ width: "auto" }}>
                                            <input
                                                style={{ paddingLeft: '15px' }}
                                                className={formik.errors?.first_name !== undefined ? 'search-input-error' : "search-input"}
                                                placeholder=""
                                                type="name"
                                                id="first_name"
                                                name="type"
                                                value={formik.values.first_name}
                                                onChange={(e: any) => handleChange(e, 'first_name')}
                                            // disabled={scheduledAgain ? true : false}
                                            />
                                        </div>
                                        {formik.errors.first_name && <p className="error-text">{formik.errors.first_name}</p>}
                                    </div>

                                    {/* {formik.errors.email && <p className="error-text">{formik.errors.email}</p>} */}

                                    <div className="schedule-input-container">
                                        <div className="schedule-label">Last Name*</div>
                                        <div style={{ width: "auto" }}>
                                            <input
                                                style={{ paddingLeft: '15px' }}
                                                className={formik.errors?.last_name !== undefined ? 'search-input-error' : "search-input"}
                                                placeholder=""
                                                type="name"
                                                id="last_name"
                                                name="type"
                                                value={formik.values.last_name}
                                                onChange={(e: any) => handleChange(e, 'last_name')}
                                            // disabled={scheduledAgain ? true : false}
                                            />
                                        </div>
                                        {formik.errors.last_name && <p className="error-text">{formik.errors.last_name}</p>}
                                    </div>
                                    <div className="schedule-input-container">
                                        <div className="schedule-label">Email*</div>
                                        <div style={{ width: "auto" }}>
                                            <input
                                                style={{ paddingLeft: '15px' }}
                                                className={formik.errors.email !== undefined ? 'search-input-error' : "search-input"}
                                                placeholder=""
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={(e: any) => handleChange(e, 'email')}
                                            // disabled={scheduledAgain ? true : false}
                                            />
                                        </div>
                                        {formik.errors.email && <p className="error-text">{formik.errors.email}</p>}
                                    </div>
                                    {/* {formik.errors.first_name && <p className="error-text">{formik.errors.first_name}</p>} */}

                                    <div className="schedule-input-container">
                                        <div className="schedule-label">Phone Number*</div>
                                        <div style={{ width: "auto" }}>
                                            <PhoneInput containerStyle={{ width: 'auto' }}
                                                placeholder="Enter your phone number"
                                                inputStyle={{
                                                    borderRadius: '30px',
                                                    border: formik.errors.phone !== undefined ? '1px solid #ef4444' : '1px solid #c0cece',
                                                    // color: scheduledAgain ?  'hsl(0, 0%, 60%) !important' :''
                                                    height: "51px"
                                                }}
                                                enableTerritories={true}
                                                country={"jm"}
                                                enableSearch={true}
                                                value={formik.values.phone}
                                                onChange={(e: any) => handleChange(e, 'phone')}
                                                autoFormat={false}
                                            // disabled={scheduledAgain ? true : false}
                                            />
                                        </div>
                                        {formik.errors.phone && <p className="error-text">{formik.errors.phone}</p>}

                                    </div>

                                </div>}
                        </div>
                        {formik.values?.deposit !== '0' ? <div className="schedule-continue-container">
                            <div className="schedule-continue" onClick={() => setShowPayModal(true)}>
                                Pay
                            </div>
                        </div> :
                            <div className="schedule-continue-container">
                                <div className="schedule-continue" onClick={() => formik.handleSubmit()}>
                                    Continue
                                </div>
                            </div>}
                        <Modal
                            className="dwnld-modal"
                            open={showPayModal}
                            onCancel={() => { setShowPayModal(false) }}
                        >
                            <div className="dwnld-main_container" style={{ height: "430px" }}>
                                <div className="dwnld-container">
                                    <button className="btn-modal close-button" aria-label="Close" onClick={() => setShowPayModal(false)}>
                                        <img alt='' src={images.IMG_CROSS} className="image_close" />
                                    </button>
                                    <div className="dwnld-title">Feeling Hangry?</div>
                                    <img alt='' src={images.IMG_PLATE} className="egg-new-image" />
                                    <p className="dwnld-text">Download the visuEats app to
                                        pay</p>
                                    {isMobile === isAndroid ? <a href="https://play.google.com/store/apps/details?id=com.visueatsapp" className="btns dwnld-btn" >Download the App </a > : <a href="https://apps.apple.com/app/visueats/id6443964680" className="btns dwnld-btn" >Download the App </a >}
                                </div>
                            </div>

                        </Modal>
                    </div>

                }
            </> : <div className="error-main-container">
                <div className="error-container">
                    <h1 className="error-message">
                        Oh no! <br />
                        We only found crumbs.
                    </h1>
                    <p>
                        {" "}
                        We're sorry! This restaurant is currently not accepting reservations.
                    </p>
                    <div className="error-img">
                        <img src={images.IMG_ERROR} className="error-image" alt="icon" />
                    </div>
                </div>

            </div>}
            <div className='schedule-reservation-footer'>
                <Footer />
            </div>
        </>
    )
}

export default MakeReservation