import React, { FC, useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import ReactPlayer from 'react-player'
import { Rings } from "react-loader-spinner";
import { collection, getDocs, query, where } from "firebase/firestore";
import { GoDotFill } from "react-icons/go";
import { FaAngleDoubleUp } from "react-icons/fa";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";

import { getAllOrderedItemsByRestaurantID, getAllPreOrderItemByRestauarntID, images } from "../../commonConfig";
import { db } from "../../firebase/firebase";
import { day, Restaurant } from "./interface";
import Loader from "../../components/loader/loader";
import Footer from "../../components/molecules/Footer/Footer";
import CategoryTab from "../../components/organisms/CategoryTab/CategoryTab";
import Category from "../../components/organisms/Category/Category";
import { Carousel, Modal } from "antd";
import { isMobile, isAndroid, } from 'react-device-detect'

import "./Homepage.scss";
import { taxProps, logoImageProps } from "../../interfaces";
import { TrackGoogleAnalyticsEvent } from "../../googleAnalytics/GoogleAnalytics";
import { useNavigate } from "react-router-dom";
import FeedbackModal from "../../components/molecules/feedback/feedback";
import CategoryMenuItem from "../../components/molecules/Category-Menu-Item/Categorymenu";
var encodedAddress = '';

const Homepage: FC = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [categoryLoader, setCategoryLoader] = useState<boolean>(true);
  const [restaurantId, setRestaurantId] = useState<string>("");
  const [restaurantDetail, setRestaurantDetail] = useState<Restaurant | any>();
  const [bannerImage, setBannerImage] = useState<string | undefined>("");
  const [logoImage, setLogoImage] = useState<string | undefined>("");
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<day | undefined>({});
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [tags, setTags] = useState<any[]>([]);
  const [gradient, setGradient] = useState<any>([0]);
  const [selectedTag, setSelectedTag] = useState<string>();
  const [categoryOpened, setCategoryOpened] = useState<any>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [cardIndex, setCardIndex] = useState<number>(0);
  const [fix, setFix] = useState<boolean>(false);
  const [fix1, setFix1] = useState<boolean>(false);
  const [localTax, setLocalTax] = useState<number>();
  const [serviceCharge, setServiceCharge] = useState<number>();
  const [showSearch, setShowSearch] = useState(false);
  const [subCategoryData, setSubCategoryData] = useState<any[]>([]);
  const [sponsorData, setSponsorData] = useState<any>([]);
  const [adOpen, setAdOpen] = useState(false);
  // const [showModal, setShowModal] = useState(false)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [showOrderModal, setShowOrderModal] = useState(false)
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [videoId, setVideoId] = useState<any>()
  const [isPlaying, setIsPlaying] = useState<any>(false)
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [uniqId, setUniqId] = useState<any>(null);
  const [feedback, setFeedback] = useState<any[]>([]);
  const [highestVotedRating, setHighestVotedRating] = useState<number | any>(0)
  const [totalVotesForHighestRated, setTotalVotesForHighestRated] = useState<number | any>(0)
  const [ratingsCount, setRatingsCount] = useState<any>()
  const [menuItemRatings, setMenuItemRatings] = useState<any[]>([])
  const [crowdOpen, setCrowdOpen] = useState<boolean>(false)
  const [top10MenuItems, setTop10MenuItems] = useState<any>([])

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const navigate = useNavigate();
  const getTimings = (daysData: any, selectedDay: any) => {
    const time = daysData?.find((day: any) => day.days.includes(selectedDay));
    if (time) {
      return time;
    } else {
      return false;
    }
  };


  // Getting restaurant details from firebase ...
  const getDataFromFirebase = async () => {
    setLoader(true);
    const ResCollectionRef = collection(db, "Restaurant");
    let URL: string = window.location.href;
    setUniqId(window.location.pathname)
    if (window.location.search) {
      URL = URL.split(window.location.search)[0];
    }

    const getDetailQuery = await getDocs(
      query(ResCollectionRef, where("url", "==", URL))
    );
    if (getDetailQuery.empty) {
      setLoader(false);
    } else {
      getDetailQuery.forEach(async (doc) => {
        const restaurantData = doc.data();

        // set banner image size ...
        if (restaurantData?.bannerImage?.includes(".png")) {
          setBannerImage(
            restaurantData?.bannerImage?.replace(".png", "_600x600.png")
          );
        } else if (restaurantData?.bannerImage?.includes(".jpeg")) {
          setBannerImage(
            restaurantData?.bannerImage?.replace(".jpeg", "_600x600.jpeg")
          );
        } else if (restaurantData?.bannerImage?.includes(".jpg")) {
          setBannerImage(
            restaurantData?.bannerImage?.replace(".jpg", "_600x600.jpg")
          );
        }
        // setBannerImage(restaurantData?.bannerImageCropedBase64);
        // setLogoImage(restaurantData?.companyLogoCropedBase64);

        // set logo size ...
        if (restaurantData?.companyLogoImage?.includes(".png")) {
          setLogoImage(
            restaurantData?.companyLogoImage?.replace(".png", "_200x200.png")
          );
        } else if (restaurantData?.bannerImage?.includes(".jpeg")) {
          setLogoImage(
            restaurantData?.companyLogoImage?.replace(".jpeg", "_200x200.jpeg")
          );
        } else if (restaurantData?.bannerImage?.includes(".jpg")) {
          setLogoImage(
            restaurantData?.companyLogoImage?.replace(".jpg", "_200x200.jpg")
          );
        }

        // Setting tax details ...
        if (restaurantData?.localTaxVisibility) {
          setLocalTax(restaurantData.localTax);
        }
        if (restaurantData?.serviceChargeVisibility) {
          setServiceCharge(restaurantData.serviceCharge);
        }

        // set Open/Close status ...
        if (restaurantData?.timezone && restaurantData?.hoursOperation) {
          const { timezone, hoursOperation } = restaurantData;
          const today = moment(new Date()).tz(timezone);
          const selectedDateObj = getTimings(hoursOperation, today.format("dddd"));
          setSelectedDay(selectedDateObj);

          if (selectedDateObj?.openTime && selectedDateObj?.closeTime) {
            let startTime: any = parseFloat(moment(selectedDateObj.openTime, ["hh:mm a"], timezone).format("HH.mm"));
            let endTime: any = parseFloat(moment(selectedDateObj.closeTime, ["hh:mm a"], timezone).format("HH.mm"));
            let currentTime: any = parseFloat(moment(new Date()).tz(timezone).format("HH.mm"));

            endTime += startTime > endTime ? 24 : 0;

            if (currentTime >= startTime && currentTime <= endTime) {
              setOpenStatus(true);
            } else {
              const yesterday = today.clone().subtract(1, 'days');
              const yesterdayDateObj = getTimings(hoursOperation, yesterday.format("dddd"));
              if (yesterdayDateObj?.openTime && yesterdayDateObj?.closeTime) {
                let yesterdayStartTime: any = parseFloat(moment(yesterdayDateObj.openTime, ["hh:mm a"], timezone).format("HH.mm"));
                let yesterdayEndTime: any = parseFloat(moment(yesterdayDateObj.closeTime, ["hh:mm a"], timezone).format("HH.mm"));
                setOpenStatus(yesterdayStartTime > yesterdayEndTime && yesterdayEndTime >= currentTime)
              } else {
                setOpenStatus(false)
              }
            }
          }
        }

        setRestaurantDetail({ ...restaurantData, id: doc.id });
        const address = `${restaurantData.add1} ${restaurantData.city} ${restaurantData.state}`;
        encodedAddress = encodeURIComponent(address);
        setRestaurantId(doc.id);

        const subCategory: any[] = [];
        await db
          .collection("Restaurant")
          .doc(doc.id)
          .collection("SubCategory")
          .where("hide", "==", false)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc: any) => {
              subCategory.push(doc.data());
            });
          });
        setSubCategoryData(subCategory);
      });
    }
  };

  // Getting restaurant's sponsor data ...
  const getSponsorData = async () => {
    const sponsorsRef = collection(
      db,
      `Restaurant/${restaurantDetail?.id}/Sponsors`
    );
    const sponsorQuery = query(
      sponsorsRef,
      where("sponser_hide_status", "==", false)
    );
    const sponsorsShot = await getDocs(sponsorQuery);
    const sponsorsList = sponsorsShot.docs.map((doc) => doc.data());
    setSponsorData(sponsorsList);
  };


  const getFeedbackData = async () => {
    await db
      .collection("Feedback")
      .where("restaurantId", "==", restaurantDetail.id)
      .get()
      .then(async (snapshot) => {
        const feedbackData = snapshot.docs.map((doc) => doc.data());
        let ratingCounts: any = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0
        };

        // Count the number of votes for each rating
        feedbackData.forEach((item) => {
          const overallRating = item?.ratings?.overall;
          if (overallRating !== undefined) {
            ratingCounts[overallRating] += 1;
          }
        });

        // Find the rating with the highest number of votes
        const highestVotedRating = Object.keys(ratingCounts).reduce((a, b) =>
          ratingCounts[a] > ratingCounts[b] ? a : b
        );

        // Find the total number of votes for the highest voted rating
        const totalVotesForHighestRated = ratingCounts[highestVotedRating];

        // If there are multiple ratings with the same highest vote count,
        // choose the one with the highest value
        const highestRated = Object.keys(ratingCounts).reduce((a, b) => {
          if (ratingCounts[a] === ratingCounts[b]) {
            return parseInt(a) > parseInt(b) ? a : b;
          }
          return ratingCounts[a] > ratingCounts[b] ? a : b;
        });

        setTotalVotesForHighestRated(totalVotesForHighestRated);
        setFeedback(feedbackData);
        setHighestVotedRating(parseInt(highestRated)); // Convert to integer
        setRatingsCount(ratingCounts);

      })
      .catch((err) => console.log(err));
    setLoader(false);

  };

  const getMenuFeedbackData = async () => {
    var menufeedbackRating: any = []
    var feedbackData: any
    await db
      .collection("Feedback")
      .where("restaurantId", "==", restaurantDetail.id)
      .get()
      .then(async (snapshot) => {
        snapshot.docs.map((doc) => {
          feedbackData = doc.data()?.menuItemRatings
          if (feedbackData && feedbackData.length) {
            menufeedbackRating = [...menufeedbackRating, ...feedbackData]
          }
        })
        setMenuItemRatings(menufeedbackRating)
      }).catch((err) => console.log(err));
    setLoader(false);
  }

  // Setting-up restaurant's menu details ...
  const setMenuData = async () => {
    await db
      .collection(`Restaurant/${restaurantId}/Menu`)
      .orderBy("menuIndex")
      .get()
      .then(async (snapshot) => {
        const menuData = snapshot.docs.map((doc) => doc.data());
        const arr: any = []
        menuData.map((data: any) => !data?.menuStatus ? arr.push(data) : '')
        setTags(arr);
      })
      .catch((err) => console.log(err));
    setLoader(false);
  };

  // Getting category data ...
  const getCategory = async () => {
    if (!loader) {
      setCategoryLoader(true);
      await db
        .collection(`Restaurant/${restaurantId}/Category`)
        .where(
          "menuType",
          "array-contains",
          selectedTag ? selectedTag : tags[0]?.name
        )
        .where("categoryStatus", "==", true)
        .orderBy("categoryIndex")
        .get()
        .then(async (querySnapshot: any) => {
          const threadsQueried = await querySnapshot.docs.map(
            (documentSnapshot: any) => {
              return {
                ...documentSnapshot.data(),
              };
            }
          );
          await threadsQueried.sort(
            (a: any, b: any) => a.categoryIndex - b.categoryIndex
          );
          setCategory(threadsQueried);
        });
      setCategoryLoader(false);
    }
  };

  // Setting up time for tags ...
  const setTimeIndex = (number: number) => {
    if (gradient.includes(number)) {
      setGradient([]);
    } else {
      setGradient([number]);
    }
  };

  // Category open handler ...
  const categoryOpenHandler = (i: any) => {
    if (categoryOpened.includes(i + 1)) {
      let temp = [...categoryOpened];
      let index = temp.findIndex((id) => id === i + 1);
      temp.splice(index, 1);
      setCategoryOpened(temp);
    } else {
      setCategoryOpened([...categoryOpened, i + 1]);
    }
  };

  // handleScroll event listener function ...
  const handleScroll = () => {
    // set card index ...
    const index = Math.floor(window.scrollY / 370);
    if (index >= category?.length - 1) {
      setCardIndex(category?.length - 1);
    } else {
      setCardIndex(index);
    }
  };

  const scrollFoter = () => {
    // setting postion ...
    const element: any = document.querySelector("#footer");
    const position = element?.getBoundingClientRect();
    if (position?.top < window.innerHeight - 250 && position.bottom >= 0) {
      setFix(true);
      setFix1(false);
    } else {
      setFix(false);
    }
  };

  const handleTimeDropDown = () => {
    // set restaurant's time detail dropdown ...
    if (window.scrollY >= 220) {
      setDropdown(false);
    }
    if (window.scrollY >= 460) {
      setFix1(true);
    } else {
      setFix1(false);
    }
  };



  //get top ten menu data for crowd please
  const getTopTenMenuItems = async () => {
    var preOrderItemsData = await getAllPreOrderItemByRestauarntID(restaurantId)
    var orderedItemsData = await getAllOrderedItemsByRestaurantID(restaurantId)
    const combinedArray = preOrderItemsData.concat(orderedItemsData);
    const mergedItems: any = {};

    combinedArray.forEach((item: any) => {
      const { id, quantity } = item;
      if (mergedItems[id]) {
        mergedItems[id].quantity += quantity;
      } else {
        mergedItems[id] = { ...item };
      }
    });
    let maxCount = 0;
    Object.values(mergedItems).forEach((item: any) => {
      if (item.quantity > maxCount) {
        maxCount = item.quantity;
      }
    });
    const sortedMergedArray = Object.values(mergedItems).sort((a: any, b: any) => b.quantity - a.quantity);
    const firstTenItems = sortedMergedArray.slice(0, 10);

    const menuItemsData: any = [];
    await db.collection('Restaurant').doc(restaurantId)
      .collection('Items')
      .where("item_status", "==", false)
      .orderBy('menuIndex')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          await menuItemsData.push({ ...doc.data(), id: doc.id })
          await menuItemsData.sort((a: any, b: any) => a.menuIndex - b.menuIndex);
        });
      }).catch(err => console.log(err));

    const top10Items = menuItemsData.filter((item: any) => firstTenItems.some((sortedItem: any) => sortedItem.id === item.id));
    setTop10MenuItems(top10Items)
  
  }

  const taxProps: taxProps = {
    localTax,
    serviceCharge,
  };

  const logoImageProps: logoImageProps = {
    logoImage,
  };

  useEffect(() => {
    if (restaurantDetail && (selectedTag || tags[0]?.name)) {
      getCategory();
      getSponsorData();
    }
  }, [selectedTag || tags[0]?.name]);

  useEffect(() => {
    if (restaurantDetail) {
      setMenuData();
      getFeedbackData()
      getMenuFeedbackData()
    }
  }, [restaurantDetail]);

  useEffect(() => {
    setTimeout(() => {
      getDataFromFirebase();
    }, 1000);
  }, []);

  useEffect(() => {
    if(restaurantId){
      getTopTenMenuItems()
    }
  }, [restaurantId, feedback, menuItemRatings])

  const getTimingsMenu = (daysData: any, selectedDay: any) => {
    const time = daysData?.find((day: any) => day.days.includes(selectedDay));
    if (time) {
      return time;
    } else {
      return false
    }
  }

  const handleDisplayHours = () => {
    var element: any;
    var timezone: any = restaurantDetail?.timezone
    const today = moment(new Date()).tz(timezone);
    let currentTime: any = parseFloat(moment(new Date()).tz(timezone).format("HH.mm"));
    if (selectedTag === undefined) {
      var selectedDateObj = getTimingsMenu(tags[0].hoursOperation, today.format("dddd"));
      let startTime: any = parseFloat(moment(selectedDateObj.openTime, ["hh:mm a"], timezone).format("HH.mm"));
      let endTime: any = parseFloat(moment(selectedDateObj.closeTime, ["hh:mm a"], timezone).format("HH.mm"));

      endTime += startTime > endTime ? 24 : 0;
      if (currentTime >= startTime && currentTime <= endTime) {
        element = <span className="menu-time">{`${selectedDateObj.openTime} - ${selectedDateObj.closeTime}`}</span>
      } else {
        const yesterday = today.clone().subtract(1, 'days');
        const yesterdayDateObj = getTimingsMenu(tags[0].hoursOperation, yesterday.format("dddd"));
        if (yesterdayDateObj?.openTime && yesterdayDateObj?.closeTime) {
          let yesterdayStartTime: any = parseFloat(moment(yesterdayDateObj.openTime, ["hh:mm a"], timezone).format("HH.mm"));
          let yesterdayEndTime: any = parseFloat(moment(yesterdayDateObj.closeTime, ["hh:mm a"], timezone).format("HH.mm"));
          if (yesterdayStartTime > yesterdayEndTime && yesterdayEndTime >= currentTime) {
            element = <span className="menu-time">{`${selectedDateObj.openTime} - ${selectedDateObj.closeTime}`}</span>
          } else {
            element = <span className="menu-time">Closed</span>
          }

        } else {
          element = <span className="menu-time">Closed</span>
        }
      }

    } else {
      var data: any = tags?.filter((tag) => tag.name === selectedTag)
      var selectedDateObj1 = getTimingsMenu(data[0].hoursOperation, today.format("dddd"));
      let startTime: any = parseFloat(moment(selectedDateObj1.openTime, ["hh:mm a"], timezone).format("HH.mm"));
      let endTime: any = parseFloat(moment(selectedDateObj1.closeTime, ["hh:mm a"], timezone).format("HH.mm"));
      endTime += startTime > endTime ? 24 : 0;
      if (currentTime >= startTime && currentTime <= endTime) {
        element = <span className="menu-time">{`${selectedDateObj1.openTime} - ${selectedDateObj1.closeTime}`}</span>
      } else {
        const yesterday = today.clone().subtract(1, 'days');
        const yesterdayDateObj = getTimingsMenu(data[0].hoursOperation, yesterday.format("dddd"));
        if (yesterdayDateObj?.openTime && yesterdayDateObj?.closeTime) {
          let yesterdayStartTime: any = parseFloat(moment(yesterdayDateObj.openTime, ["hh:mm a"], timezone).format("HH.mm"));
          let yesterdayEndTime: any = parseFloat(moment(yesterdayDateObj.closeTime, ["hh:mm a"], timezone).format("HH.mm"));
          if (yesterdayStartTime > yesterdayEndTime && yesterdayEndTime >= currentTime) {
            element = <span className="menu-time">{`${selectedDateObj1.openTime} - ${selectedDateObj1.closeTime}`}</span>
          } else {
            element = <span className="menu-time">Closed</span>
          }
        } else {
          element = <span className="menu-time">Closed</span>
        }
      }
    }
    return element;
  }

  window.addEventListener("scroll", handleScroll);
  window.addEventListener("scroll", handleTimeDropDown);
  window.addEventListener("scroll", scrollFoter);

  const handleVideoChange = (currentSlide: number) => {
    const element: any = document.getElementById(`PromotionImg${currentSlide}`);
    setVideoId(element?.id);
    onVideoPause(element?.id)
    onVideoStart(element?.id)
  }

  const onVideoStart = (e: any) => {
    if (e === videoId) {
      setIsPlaying(true)
    }
  }

  const onVideoPause = (e: any) => {
    setIsPlaying(false)
  }
  const openFullscreen = (e: any) => {
    document.getElementById(e)?.requestFullscreen();
    setFullScreen(true)
  }

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setFullScreen(false)
  }

  const navigateToScheduleReservation = () => {
    var navigateURL = `${uniqId}/schedule-reservation`
    navigate(navigateURL)
  }

  return (
    <>
      {!loader ? (
        <>
          {restaurantDetail ? (
            <div className="Homepage">
              <div>
                <div className="image-section">
                  <div>
                    <Carousel dots={true}
                      autoplay={isPlaying ? false : true}
                      autoplaySpeed={5000}
                      effect={isPlaying ? 'scrollx' : 'scrollx'}
                      afterChange={(currentSlide: number) => handleVideoChange(currentSlide)}
                      beforeChange={(currentSlide: number) => handleVideoChange(currentSlide)}
                    >
                      <><div style={{ display: "flex", position: "relative" }}>
                        <div
                          className="search-bar-icon"
                          onClick={() => setShowSearch(true)}
                        >
                          <img src={images.IMG_SEARCH} className="search-icon-img" alt="icon" />
                        </div>
                        {showSearch && (
                          <div className="input-field">
                            <div className="search-input-container">
                              <div className="search-input">
                                <div className="search-icon">
                                  <img
                                    src={images.IMG_SEARCH}
                                    className="search-icon-img" alt="icon"
                                  />
                                </div>
                                <input
                                  placeholder="Try searching fries"
                                  type="text"
                                  id="search-bar"
                                  name="search-bar"
                                  value={searchValue}
                                  onChange={(e) => { setSearchValue(e.target.value); e.preventDefault() }}
                                  className="input-searchbar"
                                />
                                <button className="cross-icon-home btns">
                                  <img alt="icon"
                                    src={images.IMG_CROSS}
                                    className="cross-icon-img"
                                    onClick={() => { setShowSearch(false); setSearchValue('') }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <div
                          className="order-bar-icon"
                          onClick={() => setShowOrder(true)}
                        >
                          <img src={images.IMG_ORDER} className="search-icon-img" style={{width:"32px", height:"32px"}} alt="icon" />
                        </div> */}

                        <img id="PromotionImg0"
                          src={
                            // bannerImage
                            //   ? bannerImage
                            //   : restaurantDetail.bannerImage
                            //     ? restaurantDetail.bannerImage
                            //     : images.IMG_DEFAULT_IMAGE
                            restaurantDetail?.bannerImage
                            ? restaurantDetail?.bannerImage
                            : images.IMG_DEFAULT_IMAGE
                          }
                          alt="icon"
                          className="res-image"
                          onClick={() => fullScreen ? exitFullScreen() : openFullscreen("PromotionImg0")}
                        />
                      </div>
                      </>
                      {restaurantDetail?.promotions?.sort((a: any, b: any) => a.id.localeCompare(b.id, 'en', { numeric: true })).map((arr: any) => {
                        return <div style={{ position: "relative" }}>
                          <div style={{ display: "flex", position: "relative" }}>
                            <div
                              className="search-bar-icon"
                              onClick={() => setShowSearch(true)}
                            >
                              <img src={images.IMG_SEARCH} className="search-icon-img" alt="icon" />
                            </div>
                            {showSearch && (
                              <div className="input-field">
                                <div className="search-input-container">
                                  <div className="search-input">
                                    <div className="search-icon">
                                      <img
                                        src={images.IMG_SEARCH}
                                        className="search-icon-img" alt="icon"
                                      />
                                    </div>
                                    <input
                                      placeholder="Try searching fries"
                                      type="text"
                                      id="search-bar"
                                      name="search-bar"
                                      value={searchValue}
                                      onChange={(e) => { setSearchValue(e.target.value); e.preventDefault() }}
                                      className="input-searchbar"
                                    />
                                    <button className="cross-icon-home btns">
                                      <img alt="icon"
                                        src={images.IMG_CROSS}
                                        className="cross-icon-img"
                                        onClick={() => { setShowSearch(false); setSearchValue('') }}
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                            }

                            {arr.type === 'image' ?
                              <img id={arr.id} src={arr.url} alt="icon" className="res-image" onClick={() => fullScreen ? exitFullScreen() : openFullscreen(arr.id)} />
                              : <div className={isAndroid ? "cover-video" : "cover-webkit-video"}>
                                {isMobile === isAndroid ?
                                  <ReactPlayer width='100%' height='inherit' id={arr.id} playsinline controls
                                    onStart={() => onVideoStart(arr.id)} onPause={() => onVideoPause(arr.id)}
                                    url={arr.url} playing={arr.id === videoId && isPlaying} /> :
                                  <ReactPlayer width='100%' height='inherit' id={arr.id} controls autoPlay={true} playsinline
                                    url={arr.url} playing={videoId === arr.id ? true : false} />}
                              </div>}
                          </div>
                        </div>
                      })}

                    </Carousel>
                  </div>

                </div>
                <p className="border"></p>
              </div>

              <div className="res-details">
                <div className="background-image">
                  <img
                    src={
                      logoImage
                        ? logoImage
                        : restaurantDetail.companyLogoImage
                          ? restaurantDetail.companyLogoImage
                          : images.IMG_DEFAULT_LOGO
                    }
                    alt="logo"
                    className="res-logo"
                  />
                </div>
                <div className="res-name">
                  <div className="res-name-section">
                    <h1 className="name-resData">{restaurantDetail.name}</h1>
                    <div className="sub-name">
                      <span className="sub-name">{restaurantDetail.type}</span>
                      <span className="no-content">
                        {" "}
                        <GoDotFill size={8} />{" "}
                      </span>
                      <a className="a-href" rel="noreferrer" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`}>{restaurantDetail.city}</a>
                      {/* <a className="sub-name">{restaurantDetail.city}</a> */}
                    </div>
                  </div>
                  <div className="feedback_star" style={{ marginTop: "5px" }} onClick={() => { setShowFeedback(!showFeedback) }}>
                    <img className='feedback-rating-mobile' src={images.IMG_STAR_DARK_FILLED} /> {highestVotedRating} ( {totalVotesForHighestRated} )
                    {showFeedback ? (
                      <IoChevronUpSharp
                        size={20}
                        className="icon-dropdown"
                        onClick={() => { setShowFeedback(!showFeedback) }}
                      />

                    ) : (
                      <IoChevronDownSharp
                        size={20}
                        className="icon-dropdown"
                        onClick={() => { setShowFeedback(!showFeedback) }}
                      />
                    )}</div>


                  <FeedbackModal
                    open={showFeedback}
                    close={() => { setShowFeedback(false) }}
                    feedback={feedback}
                    highestVotedRating={highestVotedRating}
                    totalVotesForHighestRated={totalVotesForHighestRated}
                    ratingsCount={ratingsCount}
                  />

                  <div
                    className="status-day-section"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-8px",
                    }}
                  >
                    <div
                      className="res-status-section"
                      onClick={() => setDropdown(!dropdown)}
                    >
                      {openStatus ? (
                        <>
                          <div className="res-open-status">Open now</div>
                          <span
                            className="color-1"
                            style={{ marginBottom: "4px", color: "#779498" }}
                          >
                            {" "}
                            <GoDotFill size={8} />{" "}
                          </span>
                          <div className="color">
                            Closes at {selectedDay?.closeTime}{" "}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="res-open-red">Closed</div>{" "}
                          {selectedDay && (
                            <>
                              <span
                                className="color-1"
                                style={{ color: "#779498", marginTop: "-4px" }}
                              >
                                {" "}
                                <GoDotFill size={8} />{" "}
                              </span>
                              {selectedDay?.openTime ===
                                "Invalid date" ? null : (
                                <div className="color">
                                  Open at {selectedDay?.openTime}{" "}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {dropdown ? (
                        <IoChevronUpSharp
                          size={20}
                          className="icon-dropdown"
                          onClick={() => setDropdown(!dropdown)}
                        />

                      ) : (
                        <IoChevronDownSharp
                          size={20}
                          className="icon-dropdown"
                          onClick={() => setDropdown(!dropdown)}
                        />
                      )}

                    </div>
                    {selectedDay?.closeTime ? <div className="res-kitchen-time">Kitchen Closes at {selectedDay?.kitchenCloseTime}</div> : <div className="res-kitchen-time">Kitchen Closed</div>}
                    {selectedDay?.lastOrder ? <div className="res-kitchen-time" style={{ marginTop: "5px" }}>Last Order at {selectedDay?.lastOrder}</div> : ""}
                    {restaurantDetail?.dresscode && <div className="dress-code">{restaurantDetail?.dresscode}</div>}
                    {restaurantDetail?.verbiage && <div className="dress-code">{restaurantDetail?.verbiage}</div>}
                    {dropdown && (
                      <div
                        className="res-open-days"
                        style={{ marginLeft: "25px" }}
                      >
                        {weekDays.map(
                          (e: any, index: number) => (
                            <>
                              <div key={index}
                                style={{
                                  color: "#0d595a",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                }}
                              >
                                {e}
                                <div
                                  style={{
                                    color: "#779498",
                                    fontWeight: "600",
                                    fontSize: "12px",
                                  }}
                                >
                                  {getTimings(
                                    restaurantDetail?.hoursOperation,
                                    e
                                  )
                                    ? `${getTimings(
                                      restaurantDetail?.hoursOperation,
                                      e
                                    ).openTime
                                    } - ${getTimings(
                                      restaurantDetail?.hoursOperation,
                                      e
                                    ).closeTime
                                    } `
                                    : "Closed"}
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    )}
                  </div>

                  <div className="reserv-flex">
                    <div className="get-help-addOn-hover">
                      <div className="reservation" onClick={() => setShowDetailModal(true)}>
                        <img alt='' src={images.IMG_DETAILS_ICON} className="reserve-icon" />
                        <div className="reserve">Details</div>
                      </div>

                      <Modal
                        className="dwnld-modal"
                        visible={showDetailModal}
                        onCancel={() => { }}
                      >
                        <div className="dwnld-main_container info-main_container">
                          <div className="dwnld-container info-container">
                            <div className="info-title-flex">
                              <div className="res-info-title">Restaurant Information</div>
                              <div>
                                <button className="btn-modal btn-modal-close close-button" aria-label="Close" onClick={() => setShowDetailModal(false)}>
                                  <img alt='' src={images.IMG_COLOR_CROSS} className="image_color_close" />
                                </button>
                              </div>
                            </div>

                          </div>
                          <div style={{ height: "360px", overflow: "auto" }}>
                            <div className="info-text-container" style={{ marginTop: "28px" }}>
                              <div className="info-text-flex">
                                <div>
                                  <img alt='' src={images.IMG_MAIL_ICON} className="mail-icon" />
                                </div>
                                <div>
                                  <div className="info-title"> Email </div>
                                  <div className="email-text">
                                    <a className="a-href" target="_blank" href={`mailTo:${restaurantDetail.email}`}>{restaurantDetail.email}</a>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div className="info-text-container">
                              <div className="info-text-flex">
                                <div>
                                  <img alt='' src={images.IMG_PHONE_ICON} className="mail-icon" />
                                </div>
                                <div>
                                  <div className="info-title"> Phone Number</div>
                                  <div className="phn-no-text">
                                    <a className="a-href" rel="noreferrer" target="_blank" href={`tel:${restaurantDetail.number}`}>{restaurantDetail.number}</a>{restaurantDetail.number2 ? "," : ""} <a className="a-href" rel="noreferrer" target="_blank" href={`tel:${restaurantDetail.number2}`}>{restaurantDetail.number2}</a>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div className="info-text-container">
                              <div className="info-text-flex">
                                <div>
                                  <img alt='' src={images.IMG_LOCATION_ICON} className="address-icon" />
                                </div>
                                <div>
                                  <div className="info-title"> Address </div>
                                  <div className="address-text">
                                    <a className="a-href" rel="noreferrer" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`}>{restaurantDetail.add1} Nr. {restaurantDetail.city}, {restaurantDetail.state} {restaurantDetail.zip}</a>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div className="info-text-container" style={{ marginRight: "6px" }}>
                              <div className="info-text-flex">
                                <div>
                                  <img alt='' src={images.IMG_INSTAGRAM} className="instagram-icon" />
                                </div>
                                <div>
                                  <div className="info-title"> Instagram </div>
                                  <div className="address-text">
                                    <a className="a-href" rel="noreferrer" target="_blank" href={restaurantDetail.instagram}>{restaurantDetail.instagram}</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="info-text-container" style={{ marginRight: "6px" }}>
                              <div className="info-text-flex">
                                <div>
                                  <img alt='' src={images.IMG_FACEBOOK} className="instagram-icon" />
                                </div>
                                <div>
                                  <div className="info-title"> Facebook </div>
                                  <div className="address-text">
                                    <a className="a-href" rel="noreferrer" target="_blank" href={restaurantDetail.facebook}>{restaurantDetail.facebook}</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="info-text-container" style={{ marginRight: "6px" }}>
                              <div className="info-text-flex">
                                <div>
                                  <img alt='' src={images.IMG_TIKTOK} className="instagram-icon" />
                                </div>
                                <div>
                                  <div className="info-title"> Tiktok </div>
                                  <div className="address-text">
                                    <a className="a-href" rel="noreferrer" target="_blank" href={restaurantDetail.tiktok}>{restaurantDetail.tiktok}</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="info-text-container">
                              <div className="info-text-flex">
                                <div>
                                  <img alt='' src={images.IMG_CONDITION} className="address-icon" />
                                </div>
                                <div>
                                  <div className="info-title"> Conditions </div>
                                  <div className="address-text"> {restaurantDetail?.verbiage}</div>
                                </div>

                              </div>
                            </div>
                          </div>

                        </div>

                      </Modal>

                    </div>
                    <div className="get-help-addOn-hover">
                      {restaurantDetail?.product ?
                        <div className="reservation" onClick={() => navigateToScheduleReservation()}>
                          <img alt='' src={images.IMG_RESERVE} className="reserve-icon" />
                          <div className="reserve">Reserve</div>
                        </div> : <div className="reservation" >
                          <img alt='' src={images.IMG_RESERVE} className="reserve-icon" />
                          <div className="reserve">Reserve</div>
                          <div className="get-help-add-on-hover"> We're sorry! This restaurant is currently
                            not accepting reservations.</div>
                        </div>
                      }

                    </div>
                    {/* <Modal
                      className="dwnld-modal"
                      visible={showModal}
                      onCancel={() => { setShowModal(false) }}
                    >
                      <div className="dwnld-main_container" style={{ height: "430px" }}>
                        <div className="dwnld-container">
                          <button className="btn-modal close-button" aria-label="Close" onClick={() => setShowModal(false)}>
                            <img alt='' src={images.IMG_CROSS} className="image_close" />
                          </button>
                          <div className="dwnld-title">Feeling Hangry?</div>
                          <img alt='' src={images.IMG_EGG_NEW} className="egg-new-image" />
                          <p className="dwnld-text">Download the visuEats app to
                            make quick reservations</p>
                            <Link to={`${uniqId}/schedule-reservation`} className="btns dwnld-btn" >Download the App</Link>
                        </div>
                      </div>
                    </Modal> */}
                    <Modal
                      className="dwnld-modal"
                      visible={showOrder}
                      onCancel={() => { setShowOrder(false) }}
                    >
                      <div className="dwnld-main_container" style={{ height: "430px" }}>
                        <div className="dwnld-container">
                          <button className="btn-modal close-button" aria-label="Close" onClick={() => setShowOrder(false)}>
                            <img alt='' src={images.IMG_CROSS} className="image_close" />
                          </button>
                          <div className="dwnld-title">Feeling Hangry?</div>
                          <img alt='' src={images.IMG_PLATE} className="egg-new-image" />
                          <p className="dwnld-text">Download the visuEats app <br />
                            to use this feature!</p>
                          {isMobile === isAndroid ? <a href="https://play.google.com/store/apps/details?id=com.visueatsapp" className="btns dwnld-btn" >Download the App </a > : <a href="https://apps.apple.com/app/visueats/id6443964680" className="btns dwnld-btn" >Download the App </a >}
                        </div>
                      </div>

                    </Modal>
                    <div className="get-help-addOn-hover">
                      <div className="reservation" onClick={() => setShowOrderModal(true)}>
                        <img alt='' src={images.IMG_ORDER} className="reserve-icon" />
                        <div className="reserve">Pick up</div>
                      </div>
                    </div>
                    <Modal
                      className="dwnld-modal"
                      visible={showOrderModal}
                      onCancel={() => { setShowOrderModal(false) }}
                    >
                      <div className="dwnld-main_container" style={{ height: "430px" }}>
                        <div className="dwnld-container">
                          <button className="btn-modal close-button" aria-label="Close" onClick={() => setShowOrderModal(false)}>
                            <img alt='' src={images.IMG_CROSS} className="image_close" />
                          </button>
                          <div className="dwnld-title">Feeling Hangry?</div>
                          <img alt='' src={images.IMG_PLATE} className="egg-new-image" />
                          <p className="dwnld-text">Download the visuEats app <br /> to
                            order online</p>
                          {isMobile === isAndroid ? <a href="https://play.google.com/store/apps/details?id=com.visueatsapp" className="btns dwnld-btn" >Download the App </a > : <a href="https://apps.apple.com/app/visueats/id6443964680" className="btns dwnld-btn" >Download the App </a >}
                        </div>
                      </div>

                    </Modal>
                    <div className="get-help-addOn-hover">
                      <div className="reservation" onClick={() => setShowDeliveryModal(true)}>
                        <img alt='' src={images.IMG_BIKE} className="reserve-icon" />
                        <div className="reserve">Delivery</div>
                      </div>
                    </div>
                    <Modal
                      className="dwnld-modal"
                      visible={showDeliveryModal}
                      onCancel={() => { setShowDeliveryModal(false) }}
                    >
                      <div className="dwnld-main_container" style={{ height: "430px" }}>
                        <div className="dwnld-container">
                          <button className="btn-modal close-button" aria-label="Close" onClick={() => setShowDeliveryModal(false)}>
                            <img alt='' src={images.IMG_CROSS} className="image_close" />
                          </button>
                          <div className="dwnld-title">Feeling Hangry?</div>
                          <img alt='' src={images.IMG_PLATE} className="egg-new-image" />
                          <p className="dwnld-text">Download the visuEats app
                            to use this feature!</p>
                          {isMobile === isAndroid ? <a href="https://play.google.com/store/apps/details?id=com.visueatsapp" className="btns dwnld-btn" >Download the App </a > : <a href="https://apps.apple.com/app/visueats/id6443964680" className="btns dwnld-btn" >Download the App </a >}
                        </div>
                      </div>

                    </Modal>
                  </div>
                </div>
              </div>

              {window.innerWidth > 501 && (
                <>
                  {tags.length > 0 ? (
                    <>
                      <p className="border-2"></p>
                      <div className="menu-bar-main-section">
                        {tags.map((tag: any, index: number) => (
                          <div key={index} style={{ display: "flex", flexDirection: "column" }}>
                            <div className="menu-bar-sub-section">
                              <div className="menu-bar-title-section">
                                <div className="menu-bar-index">
                                  <div className="menu-bar">
                                    <div
                                      key={index}
                                      className={
                                        gradient.includes(index)
                                          ? "menu-tag"
                                          : "menu-tag-color"
                                      }
                                      onClick={() => {
                                        setSelectedTag(tag.name);
                                        setTimeIndex(index);
                                        setCategoryOpened([]);
                                        TrackGoogleAnalyticsEvent(tag.name, 'Menu_Name', 'Menu')
                                      }}
                                    >
                                      {tag.name}
                                    </div>
                                  </div>

                                  <>{selectedTag === tag.name ? handleDisplayHours() :
                                    selectedTag === undefined && tags[0]?.name === tag.name ? handleDisplayHours() : ''}</>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="no-tags"></div>
                  )}
                </>
              )}
              {window.innerWidth <= 500 && (<>
                {tags.length > 0 ? (<>
                  <p className="border-2"></p>
                  <div className="menu-bar-res">
                    {tags?.map((tag: any, index: number) => (
                      <div className="menu-bar-slide">
                        <div
                          key={index}
                          className={
                            gradient.includes(index)
                              ? "menu-tag-res"
                              : "menu-tag-color-res"
                          }
                          onClick={() => {
                            setSelectedTag(tag.name);
                            setTimeIndex(index);
                            setCategoryOpened([]);
                            TrackGoogleAnalyticsEvent(tag.name, 'Menu_Name', 'Menu')
                          }}
                        >
                          {tag.name} <br />

                        </div>
                        <>{selectedTag === tag.name ? handleDisplayHours() :
                          selectedTag === undefined && tags[0]?.name === tag.name ? handleDisplayHours() : ''}</>
                      </div>
                    ))}
                  </div>
                </>) : (
                  <div className="no-tags"></div>
                )}
              </>)}

              <div className="border-3"></div>
              {!categoryLoader ? (
                <>
                  {window.innerWidth <= 500 && (
                    <>
                      {!!top10MenuItems?.length &&
                        <div className="crowd-please-section">
                          <div className="crowd-please-main-content">
                            <div className="crowd-please-main-content-left">
                              <img className="crowd-please-star-icon" src={images.IMG_WHITE_STAR} alt="white_star" />
                              <span className="crowd-please-text">crowdPlease</span>
                            </div>
                            {crowdOpen ?
                              <IoChevronUpSharp className="crowd-please-arrow-icon" size={23} onClick={() => setCrowdOpen(!crowdOpen)} />
                              :
                              <IoChevronDownSharp className="crowd-please-arrow-icon" size={23} onClick={() => setCrowdOpen(!crowdOpen)} />}
                          </div>
                          {crowdOpen &&
                            <div className="crowd-please-opened-content">
                              <div className="crowd-please-opened-content-header">
                                <span className="crowd-please-opened-content-header-1">Most ordered items this week!</span>
                                {(localTax || serviceCharge) &&
                                  <span className="crowd-please-opened-content-header-2">
                                    FINAL PRICE WILL INCLUDE: {localTax && localTax + "% TAX "}
                                    {localTax && serviceCharge && "+ "}
                                    {serviceCharge && serviceCharge + "% SERVICE CHARGE"}
                                  </span>
                                }
                              </div>
                              <div className="crowd-please-opened-items-list">
                                {top10MenuItems.map((data: any, index: any) => {
                                  return (
                                    <div
                                      key={index}
                                      className="crowdPlease-card"
                                      onClick={() => {
                                        navigate(`/${restaurantDetail?.uniqId}/details`, {
                                          state: {
                                            obj: data,
                                            //item,
                                            resData: restaurantDetail,
                                            //ItemData,
                                            index,
                                            // subCategoryData,
                                            //SubCatData,
                                            menuItem: top10MenuItems,
                                            // uniqueItemArray,
                                          },
                                        });
                                        //TrackGoogleAnalyticsEvent(item.name, 'Category_Name', 'Category')
                                        //  TrackGoogleAnalyticsEvent(obj.name, 'Item_Name', 'Item')
                                      }
                                      }
                                    >

                                      <CategoryMenuItem
                                        key={index}
                                        data={data}
                                        resData={restaurantDetail}
                                        logoImageProps={logoImageProps}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          }
                        </div>
                      }
                      {category?.length > 0 ? (
                        <div className="category-section-2">
                          {category?.map((cat: any, index: number) => (
                            <Category
                              key={index}
                              item={cat}
                              onPress={() => categoryOpenHandler(index)}
                              opened={categoryOpened.includes(index + 1)}
                              category={category}
                              taxProps={taxProps}
                              logoImageProps={logoImageProps}
                              subCategoryData={subCategoryData}
                              restaurantId={restaurantId}
                              searchString={searchValue}
                              menuFeedbackData={menuItemRatings}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="no-tags-category">
                          No category's found
                        </div>
                      )}
                      {sponsorData && sponsorData.length
                        ? sponsorData?.map((ad: any, index: number) => {
                          return (
                            <div key={index}
                              style={{
                                width: "100%",
                                height: !adOpen ? "90px" : "290px",
                                marginBottom: "1.333px",
                              }}
                              onClick={() => setAdOpen(!adOpen)}
                            >
                              <div
                                className="res-sponsored-text"
                                style={{
                                  color: "white",
                                  fontFamily: "Poppins",
                                  backgroundColor: "#01a8ac",
                                  width: adOpen ? "100%" : "30%",
                                  height: adOpen ? "90px" : "auto",
                                  position: "absolute",
                                  fontWeight: adOpen ? "900" : " ",
                                  fontSize: adOpen ? "20px" : "",
                                  display: adOpen ? "flex" : "",
                                  alignItems: adOpen ? "center" : "",
                                  paddingLeft: adOpen ? "20px" : ""
                                }}
                              >
                                Sponsored
                                {adOpen ? (
                                  <IoChevronUpSharp
                                    className="opend-icon"
                                    size={20}
                                  />
                                ) : (
                                  <IoChevronDownSharp
                                    className="opend-icon"
                                    size={20}
                                  />
                                )}
                              </div>
                              <div
                                className={
                                  ad.sponser_media
                                    ? "image-section-opened"
                                    : "image-section"
                                }
                              >
                                {ad?.sponser_media && !adOpen ? (
                                  <img
                                    src={ad.sponser_media}
                                    alt="icon"
                                    className="category-image"
                                    style={{ width: "100%" }}
                                  />
                                ) : (
                                  <div>
                                    <div className="category-image"  >
                                      <img alt=''
                                        onClick={() => window.open(ad.sponser_url, "_blank")}
                                        src={ad.sponser_media}
                                        style={{
                                          // height: adOpen ? "0px" : "0",
                                          position: adOpen
                                            ? "absolute"
                                            : "inherit",
                                          left: adOpen ? 0 : "",
                                          width: "100%",
                                          paddingTop: adOpen ? "90px" : "",
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })
                        : ""}
                    </>
                  )}
                  <>
                    {window.innerWidth >= 501 && (
                      <div className="category-section">
                        <div
                          className={
                            fix1
                              ? "menu-section-fix"
                              : fix && category.length >= 2
                                ? "menu-section"
                                : "menu-section"
                          }
                        >
                          {/* <div className="menu-section-fix"> */}
                          <div className="menu-title">
                            {selectedTag || tags[0]?.name} Menu
                          </div>
                          {category.length > 0 ? (
                            <>
                              <div className="menu-section-name-category">
                                {category?.map((item: any, index: any) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        cardIndex === index
                                          ? "menu-section-name-selected"
                                          : "menu-section-name"
                                      }
                                      onClick={() => {
                                        window.scrollTo({
                                          behavior: "smooth",
                                          top: 400 * index,
                                        });
                                        TrackGoogleAnalyticsEvent(item.name, 'Category_Name', 'Category')
                                      }}
                                    >
                                      {item.name}
                                    </div>
                                  );
                                })}
                              </div>  <div className="image-sponsor-main">
                                {sponsorData && sponsorData.length
                                  ? sponsorData.map((ad: any) => {
                                    return (

                                      <div
                                        key={ad.sponser_id}
                                        className="image-sponsor-container"
                                      >
                                        <img alt="icon"
                                          src={
                                            ad.sponser_media === ""
                                              ? images.IMG_SPONSOR
                                              : ad.sponser_media
                                          }
                                          className="image-sponsor" />
                                        <div className="sponsored-text" style={{ top: "0px" }}  >
                                          Sponsored
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center" }}>

                                          <a href={ad.sponser_url} target="_blank" rel="noreferrer" className="image-sponsor-learn-more" >
                                            LEARN MORE
                                            <br />
                                            <FaAngleDoubleUp />
                                          </a>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : ""}     </div>
                            </>
                          ) : (
                            <div
                              style={{
                                textAlign: "start",
                                fontFamily: "Poppins",
                                padding: "15px 45px",
                                backgroundColor: "white",
                                color: "#026669",
                                fontSize: "18px",
                                fontWeight: "600",
                              }}
                            >
                              No category's found!
                            </div>
                          )}
                          <div>
                          </div>
                        </div>
                        <div className="tag-category-section">
                          {category?.length > 0 ? (
                            <div onScroll={handleScroll}>
                              {category?.map((item: any, index: any) => (
                                <>
                                  <CategoryTab
                                    key={index}
                                    categoryIndex={index}
                                    item={item}
                                    category={category}
                                    subCategoryData={subCategoryData}
                                    restaurantId={restaurantId}
                                    selectedTag={
                                      selectedTag ? selectedTag : tags[0]?.name
                                    }
                                    searchString={searchValue}
                                    taxProps={taxProps}
                                    tags={tags}
                                    logoImageProps={logoImageProps}
                                    menuFeedbackData={menuItemRatings}
                                  />
                                </>
                              ))}
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={images.NO_FOUND}
                                alt="icon"
                                className="no-img"
                              />
                              <div className="no-tags-menu">
                                Oops, no menu items uploaded in this category!
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                </>
              ) : (
                <div className="loader">
                  <Rings color="#2bbbb7" height={80} width={100} />
                </div>
              )}
              <div id="footer">
                <Footer />
              </div>
            </div >
          ) : (
            <div className="error-main-container">
              <div className="error-container">
                <h1 className="error-message">
                  Oh no! <br />
                  We only found crumbs.
                </h1>
                <p>
                  {" "}
                  We didn’t find a menu based on the URL you’ve entered. Check
                  that you have the correct web address.
                </p>
                <div className="error-img">
                  <img src={images.IMG_ERROR} className="error-image" alt="icon" />
                </div>
              </div>
              <div style={{ bottom: "0px" }}>
                <Footer />
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default Homepage;
